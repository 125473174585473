<template>
  <!--begin::Header-->
  <div id="kt_header" style="" class="header align-items-stretch">
    <!--begin::Container-->
    <div
      :class="{
        'container-fluid': headerWidthFluid,
        container: !headerWidthFluid
      }"
      class="d-flex align-items-stretch justify-content-between"
    >
      <!--begin::Aside mobile toggle-->
      <div
        class="d-flex align-items-center d-lg-none ms-n3 me-1"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg src="/media/icons/duotone/Text/Menu.svg" />
          </span>
        </div>
      </div>
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <!--      <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">-->
      <!--        <a href="#" class="d-lg-none">-->
      <!--          <img-->
      <!--            alt="Logo"-->
      <!--            src="media/logos/logo-blue-hewtee.png"-->
      <!--            class="h-50px"-->
      <!--          />-->
      <!--        </a>-->
      <!--      </div>-->
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
      >
        <!--begin::Navbar-->

        <template v-if="true">
          <div class="page-title d-flex align-items-center mb-5 mb-lg-0">
            <h1 class="d-flex align-items-center text-dark my-1 fs-4">
              {{ title }}
              <template v-if="isu">
                <router-link
                  to="/changelog"
                  class="badge badge-light-danger bg-hover-danger text-hover-white fw-bold fs-9 px-2 ms-2"
                  >v{{ version }}
                </router-link>
              </template>
              <template v-else>
                <span class="badge badge-light-danger fw-bold fs-9 px-2 ms-2"
                  >v{{ version }}
                </span>
              </template>
            </h1>
          </div>
        </template>
        <!--end::Navbar-->

        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar></KTTopbar>
          <!--            :timer="timer" -->
          <!--            :timer1="timer1"-->
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
import { computed, defineComponent } from "vue";
import KTTopbar from "@/layout/header/Topbar.vue";

import {
  asideDisplay,
  headerLeft,
  headerWidthFluid
} from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String
    // timer: Number,
    // timer1: Number
  },
  components: {
    KTTopbar
    // KTMenu
  },
  setup() {
    const store = useStore();
    const version = process.env.VUE_APP_ZMS_APP_VERSION;
    const isu = computed(() => {
      return store.getters.getMbUser;
    });
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isu,
      version
    };
  }
});
</script>
