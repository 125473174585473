
import { defineComponent } from "vue";
import KTUserMenu from "@/layout/header/UserMenu.vue";
import store from "@/store";

export default defineComponent({
  name: "topbar",
  // props: {
  //   timer: Number,
  //   timer1: Number
  // },
  components: {
    KTUserMenu
  },
  // data() {
  //   return {
  //     timePercentage: 100,
  //     timeExtendModal: {} as Modal,
  //     second: 1800
  //   };
  // },
  computed: {
    checkRoleSettings(): boolean {
      const roles = store.getters.getRoleNames;
      const isu = store.getters.getMbUser;
      return (
        (roles?.includes("ROLE_IT_USER") || roles?.includes("ROLE_ADMIN")) &&
        isu
      );
    }
  },
  // computed: {
  // showTime() {
  //   const hours = Math.floor((this.timer as number) / 3600);
  //   const mins = (Math.floor((this.timer as number) / 60) -
  //     hours * 60) as number;
  //   const secs = Math.floor((this.timer as number) % 60);
  //   return (
  //     mins.toString().padStart(2, "0") +
  //     ":" +
  //     secs.toString().padStart(2, "0")
  //   );
  // }
  // },
  methods: {
    goToSettings() {
      this.$router.push({
        name: "settings"
      });
    }
  }
});
