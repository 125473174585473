
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";

import {
  asideEnabled,
  contentWidthFluid,
  loaderEnabled,
  loaderLogo,
  loaderSpinner,
  themeDarkLogo,
  themeLightLogo,
  toolbarDisplay
} from "@/core/helpers/config";
import router from "@/router";
import store from "@/store";

export default defineComponent({
  name: "Layout",
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    // KTToolbar,
    // KTScrollTop,
    // KTUserMenu,
    // KTExplore,
    // KTDrawerMessenger,
    KTLoader
  },
  data() {
    // let timer = 0;
    let signOutTimer2;
    return {
      timePercentage: 100,
      second: 1800,
      signOutTimer2,
      // timer,
      signOutSecond: -1
    };
  },
  // async created() {
  //   /** Refresh дарах үед refresh token болон time expire болох хугацааг сунгах **/
  //   const stt = router.options.history.state;
  //   if (
  //     (stt["back"] === "/login" && !store.getters.getFromLogin) ||
  //     stt["back"] !== "/login"
  //   ) {
  //     await this.extendLife();
  //   } else {
  //     store.dispatch(Actions.SET_FROM_LOGIN, false);
  //   }
  // },
  setup() {
    // const store = useStore();
    const route = useRoute();
    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    onMounted(() => {
      /** Refresh дарах үед refresh token болон time expire болох хугацааг сунгах **/
      const stt = router.options.history.state;
      if (
        (stt["back"] === "/login" && !store.getters.getFromLogin) ||
        stt["back"] !== "/login"
      ) {
        extendLife();
      } else {
        store.dispatch(Actions.SET_FROM_LOGIN, false);
      }
      // extendLife();
      // check if current user is authenticated
      // if (store.getters.isAuthenticated) {
      //   router.push({ name: "login" });
      // }
      // Simulate the delay page loading
      // setTimeout(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      // }, 3000);
    });

    const signOutUser = () => {
      store.dispatch(Actions.LOGOUT);
    };

    // const extendTimeSub = () => {
    //   store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    //   store.dispatch(Actions.REFRESH_TOKEN).finally(() => {
    //     store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    //   });
    // };

    // const extendLife = () => {
    //   store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
    //   store.dispatch(Actions.REFRESH_TOKEN).then(() => {
    //     store.dispatch(Actions.TOKEN_EXPIRES).then(() => {
    //       store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
    //     });
    //   });
    // };

    const extendLife = async () => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
      store.dispatch(await Actions.REFRESH_TOKEN).finally(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });
    };

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);
        // store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        // store.dispatch(Actions.TOKEN_EXPIRES).finally(() => {
        //   store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
        // });
      }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      loaderSpinner,
      asideEnabled,
      pageTitle,
      themeLightLogo,
      themeDarkLogo,
      signOutUser,
      extendLife
      // extendTimeSub
    };
  }
});
