<template>
  <!--begin::Page loader-->
  <template v-if="loaderType === 'spinner-logo'">
    <div class="page-loader flex-column">
      <img alt="Logo" class="max-h-75px" :src="gifsrc" />
      <!--      <span class="spinner-border text-primary" role="status"></span>-->
      <!--      <span class="text-muted fs-6 fw-bold mt-5">Уншиж байна...</span>-->
    </div>
  </template>
  <template v-else-if="loaderType === 'spinner-message'">
    <div class="page-loader flex-column">
      <div class="d-flex align-items-center mt-5">
        <img alt="Logo" class="max-h-75px" :src="spinner" />
        <span class="spinner-border text-primary"></span>
        <span class="text-muted fs-6 fw-bold ms-5">Loading...</span>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="page-loader">
      <span class="spinner-border text-primary">
        <span class="visually-hidden">Уншиж байна...</span>
      </span>
    </div>
  </template>
  <!--end::Page Loader-->
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Loader",
  props: {
    logo: String,
    spinner: String
  },
  setup() {
    const store = useStore();

    const loaderType = computed(() => {
      return store.getters.layoutConfig("loader.type");
    });

    return {
      loaderType
    };
  },
  computed: {
    gifsrc: function() {
      const timestamp = new Date().getTime();
      return require("/public/media/mbloader.gif") + "?" + timestamp;
    }
  }
});
</script>
