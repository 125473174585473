
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "@/core/config/MainMenuConfig";
import { useStore } from "vuex";

export default defineComponent({
  name: "kt-menu",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const hasActiveChildren = match => {
      return route.path.indexOf(match) !== -1;
    };

    const ismbuser = computed(() => {
      return store.getters.getMbUser;
    });

    const roles = computed(() => {
      return store.getters.getRoleNames;
    });

    let menu = MainMenuConfig as string[];
    const roleStore = store.getters.getRoleNames;

    // admin эрхтэй бол бүх menu-г харж болно.
    if (!roleStore.includes("ROLE_ADMIN")) {
      //heading -> pages removed
      menu.forEach(e => {
        e["pages"] = e["pages"].filter(
          a => a["role"] === undefined || roleStore.includes(a["role"])
        );
      });

      //heading -> pages -> sectionTitle -> sub removed zarim neg menu deer 2 bolon tuunees deesh role shalgaj baigaa tul role1 gej nemew zowhon sub menu deer
      menu.forEach(e => {
        e["pages"].forEach(a => {
          if (a["sectionTitle"] !== undefined) {
            a["sub"] = a["sub"].filter(
              b =>
                roleStore.includes(b["role"]) || roleStore.includes(b["role1"])
            );
          }
        });
      });

      //heading -> pages -> sectionTitle removed
      menu.forEach(e => {
        e["pages"] = e["pages"].filter(
          a => a["sectionTitle"] === undefined || a["sub"]?.length > 0
        );
      });

      //heading removed
      menu = menu.filter(e => e["pages"]?.length > 0);
    }

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      roles,
      ismbuser,
      menu
    };
  }
});
