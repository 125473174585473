const DocMenuConfig: object = [
  {
    heading: "Лавлагаа",
    pages: [
      {
        heading: "Лавлагаа",
        route: "/reference",
        svgIcon: "/media/icons/duotone/Code/Compiling.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_NEW_USER"
        // role: "ROLE_GET_INQUIRY"
      },
      /**
       *
       * CHANGED202312 ROLE_NEW_USER нэмэв
       *
       * */
      {
        heading: "Лавлагааний тайлан",
        route: "/inquiryreference",
        svgIcon: "/media/icons/duotone/Files/Compiled-file.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_INQUIRY_REPORT"
      }
    ]
  },
  {
    heading: "Төлбөр",
    pages: [
      {
        heading: "Төлөлтийн мэдээлэл",
        route: "/listpayments",
        svgIcon: "/media/icons/duotone/Shopping/ATM.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_PAID_INFO"
      },
      {
        heading: "Төлбөрийн мэдээлэл",
        route: "/bankspayment",
        svgIcon: "/media/icons/duotone/Shopping/Wallet.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_PAYMENT_INFO"
      }
    ]
  },
  {
    heading: "Хэрэглэгч",
    pages: [
      {
        heading: "Хэрэглэгчид",
        route: "/users",
        svgIcon: "/media/icons/duotone/General/User.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_MB_BRANCH_USER"
      },
      {
        heading: "Хэрэглэгчийн эрх",
        route: "/userrole",
        svgIcon: "/media/icons/duotone/Interface/Lock.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_USER_ROLE_SETTINGS_MENU"
      },
      {
        heading: "Байгууллагууд",
        route: "/organization",
        svgIcon: "/media/icons/duotone/Home/Building.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_MANAGE_ORG"
      },
      {
        heading: "Бүртгэлгүй этгээд /УБЕГ/",
        route: "/uncheck",
        svgIcon: "/media/icons/duotone/Interface/User.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_IT_USER"
      }
    ]
  },
  {
    heading: "Бүртгэл",
    pages: [
      {
        sectionTitle: "Лог бүртгэл",
        route: "/logtracks",
        svgIcon: "/media/icons/duotone/Communication/Clipboard-list.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Мэдээллийн лог",
            route: "/logtracks/loginfo",
            role: "ROLE_INQUIRY_LOG"
          },
          {
            heading: "Системийн лог",
            route: "/logtracks/logsystem",
            role: "ROLE_SYSTEM_LOG"
          },
          {
            heading: "Нэвтрэх лог",
            route: "/logtracks/loglogin",
            role: "ROLE_LOGIN_LOG"
          },
          {
            heading: "XML хүлээн авах лог",
            route: "/logtracks/logxmlreceive",
            role: "ROLE_XML_RECEIVE_LOG"
          },
          {
            heading: "Холболтын лог",
            route: "/logtracks/logconnection",
            role: "ROLE_XML_CONN_LOG"
          }
        ]
      },
      {
        sectionTitle: "Тайлан",
        route: "/reports",
        svgIcon: "/media/icons/duotone/Files/File.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Үлдэгдэлийн тайлан",
            route: "/reports/reportindex",
            role: "ROLE_SIMPLE_REPORT_RPT_MENU"
          },
          {
            heading: "Хянан шалгагч",
            route: "/reports/reportsupervisor",
            role: "ROLE_SUPERVISOR_REPORT"
          }
        ]
      },
      {
        sectionTitle: "Хүсэлтүүд",
        route: "/listrequest",
        svgIcon: "/media/icons/duotone/Files/Selected-file.svg",
        fontIcon: "bi-archive",
        sub: [
          {
            heading: "Засах хүсэлт",
            route: "/listrequest/edit",
            role: "ROLE_REQUEST_REVISE",
            role1: "ROLE_REQUEST_APPROVE"
          },
          {
            heading: "Устгах хүсэлт",
            route: "/listrequest/delete",
            role: "ROLE_REQUEST_REVISE",
            role1: "ROLE_REQUEST_APPROVE"
          },
          {
            heading: "Нийт хүсэлт",
            route: "/listrequest/total",
            role: "ROLE_ALL_REQUEST"
          }
        ]
      }
    ]
  },
  {
    heading: "Зээл",
    pages: [
      {
        heading: "Зээл бүртгэх форм",
        route: "/loan/form",
        svgIcon: "/media/icons/duotone/Shopping/Dollar.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_FORM_MENU"
      },
      {
        heading: "Зээлийн жагсаалт",
        route: "/loan/list",
        svgIcon: "/media/icons/duotone/Shopping/Money.svg",
        fontIcon: "bi-app-indicator",
        role: "ROLE_FORM_MENU"
      }
    ]
  }
];

export default DocMenuConfig;
