
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const signOut = () => {
      store.dispatch(Actions.LOGOUT).then(() => router.push({ name: "login" }));
    };

    const userdataFromSetup = computed(() => {
      return store.getters.currentUser;
    });

    return {
      userdataFromSetup,
      signOut
    };
  }
});
